<template>
  <v-app>
    <v-card flat>
      <v-toolbar color="primary" dark extended flat>
        <v-row align="center">
          <v-col cols="4"> </v-col>
          <v-col cols="4" class="text-center display-2">
            <b>{{ survey ? survey.title : '-' }}</b>
          </v-col>
          <v-col cols="4" class="text-right">
            <template v-if="$store.state.contact.token">
              Bem-vindo <b>{{ $store.state.contact.contact.name }}</b
              >!

              <v-btn
                class="ml-2"
                small
                outlined
                v-text="'Sair'"
                @click="logout()"
              />
            </template>
          </v-col>
        </v-row>
      </v-toolbar>

      <v-card
        :loading="loading"
        class="mx-auto"
        max-width="700"
        style="margin-top: -49px;"
      >
        <template slot="progress">
          <v-progress-linear color="secondary" indeterminate />
        </template>
        <router-view />
      </v-card>
    </v-card>

    <core-snackbar />
  </v-app>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import answerSurveyssApi from '@/api/answer-surveys'

export default {
  components: {
    CoreSnackbar: () => import('@/components/core/CoreSnackbar'),
  },

  data: () => ({}),

  computed: {
    ...mapState({
      survey: state => state.answerSurvey.survey,
      loading: state => state.answerSurvey.loading,
    }),
    ...mapGetters({
      question: 'answerSurvey/currentQuestion',
      findQuestionIndexById: 'answerSurvey/findQuestionIndexById',
      questionsLength: 'answerSurvey/questionsLength',
    }),
  },

  async mounted() {
    await this.loadSurvey()

    if (this.$route.name === 'end-survey') {
      this.goToLastQuestion()
    }
  },

  methods: {
    ...mapMutations({
      setSurvey: 'answerSurvey/SET_SURVEY',
      setLoading: 'answerSurvey/SET_LOADING',
      setIndexQuestion: 'answerSurvey/SET_INDEX_QUESTION',
    }),

    async loadSurvey() {
      try {
        this.setLoading(true)

        const response = await answerSurveyssApi.get(
          this.$route.params.surveyId,
        )

        const survey = response.data.survey

        this.setSurvey(survey)

        if (
          this.$route.name === 'answer-survey-login' &&
          this.$store.state.contact.token
        ) {
          this.$router.push(
            `/responder-pesquisa/${this.$route.params.surveyId}/perguntas`,
          )
        }
      } catch (e) {
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.setLoading(false)
      }
    },

    goToLastQuestion() {
      this.setIndexQuestion(this.questionsLength)
    },

    logout() {
      this.$store.dispatch('logoutContact')
      this.$router.push(
        `/responder-pesquisa/${this.$route.params.surveyId}/login`,
      )
    },
  },
}
</script>
